import { useApi } from "@/composition/useApi.js";

export default {
  state: {
    spinnerChart: false,
    tableData: [],
    chartData: [],
    objects: [],
    timeIntervalChart: "DAY",
  },
  mutations: {
    setSpinnerChart(state, data) {
      state.spinnerChart = data;
    },
    setChartData(state, data) {
      state.chartData = data;
    },
    setTableData(state, data) {
      state.tableData = data;
    },
    SetObjects(state, data) {
      state.objects = data;
    },
    setTimeIntervalChart(state, interval) {
      state.timeIntervalChart = interval;
    },
  },
  actions: {
    getAnprEventAnalitics({ commit, state }, { companyId }) {
      const { api } = useApi();
      commit("setSpinnerChart", true);
      return api
        .get("/anpr-events/analitics", {
          params: {
            timeInterval: state.timeIntervalChart,
            companyId,
          },
        })
        .then(r => {
          if (r.status === 200) {
            commit("setChartData", r.data);
          }
        })
        .catch(e => {
          console.error("Ошибка:", e);
        })
        .finally(() => {
          commit("setSpinnerChart", false);
        });
    },
    updateTimeIntervalChart({ commit, dispatch }, { interval, companyId }) {
      commit("setTimeIntervalChart", interval);
      dispatch("getAnprEventAnalytics", companyId);
    },
    searchAllAnprEvents({ commit }, body) {
      const { api } = useApi();
      return api
        .post("/anpr-events/search", body)
        .then(r => {
          if (r.status === 200) {
            commit("setTableData", r.data);
          }
        })
        .catch(e => {
          console.error("Ошибка:", e);
          commit("setTableData", []);
        });
    },
    searchTrackObjects({ commit }, body) {
      const { api } = useApi();
      return api
        .post("/objects/search", body)
        .then(r => {
          if (r.status === 200) {
            commit("SetObjects", r.data);
          }
        })
        .catch(e => {
          console.error("Ошибка:", e);
          commit("SetObjects", []);
        });
    },
    updateAnprEvent(ctx, { id, body }) {
      const { api } = useApi();
      return api.put(`/anpr-events/${id}`, body);
    },
  },
};
