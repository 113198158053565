<template>
  <div class="tabsMain">
    <div class="burger">
      <div class="burgerIco" @click="toggleBurgerMenu">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#B7BACB"
          class="bi bi-list"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
          />
        </svg>
      </div>
    </div>
    <GrayCover v-if="burgerIsShow" @grayCoverClickHandler="toggleBurgerMenu" />
    <div
      class="burgerMenu"
      :class="{ burgerMenu__active: burgerIsShow === true }"
    >
      <div class="burgerMenu__closeBtn" @click="toggleBurgerMenu">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.6668 16.6666L3.3335 3.33328M16.6668 3.33325L3.33351 16.6666"
            stroke="#B7BACB"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </div>
      <div class="burgerMenu__wrap">
        <ul class="burgerMenu__companies">
          <li
            class="burgerMenu__companies-inner"
            v-for="company in allCompanies"
            :key="company.id"
            @click="activeCompany(company)"
          >
            <div
              class="burgerMenu__company disabled"
              v-if="
                company.invoice &&
                company.invoice.isBlocked &&
                !$store.getters['accessIsAllowed']
              "
            >
              <div class="burgerMenu__company-name">
                {{ company.name }}
              </div>
              <div class="burgerMenu__company-ico">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  fill="#B7BACB"
                  class="bi bi-lock-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"
                  />
                </svg>
              </div>
            </div>
            <router-link
              v-else
              :to="{ name: 'schema', params: { id: company.id } }"
            >
              <div
                class="burgerMenu__company"
                :class="{
                  disabled: company.invoice && company.invoice.isBlocked,
                }"
              >
                <div class="burgerMenu__company-name">
                  {{ company.name }}
                </div>
                <div
                  class="burgerMenu__company-ico"
                  v-if="company.invoice && company.invoice.isBlocked"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    fill="#B7BACB"
                    class="bi bi-lock-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"
                    />
                  </svg>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
        <div
          class="burgerMenu__adminPanel"
          v-if="$store.getters['accessIsAllowed']"
        >
          <div class="burgerMenu__adminPanel-btn" @click="toggleAdminPanel">
            <div
              class="burgerMenu__adminPanel-ico"
              :style="{
                transform: !adminPanelBurgerVisibility
                  ? 'rotate(0)'
                  : 'rotate(90deg)',
              }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#B7BACB"
                class="bi bi-caret-right-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"
                />
              </svg>
            </div>
            <div class="burgerMenu__adminPanel-text">Админ панель</div>
          </div>
          <ul
            class="burgerMenu__adminPanel-list"
            v-show="adminPanelBurgerVisibility"
          >
            <li
              class="burgerMenu__adminPanel-item"
              @click.stop="toggleBurgerMenu"
            >
              <router-link
                :to="{
                  name: 'adminPanelPage',
                  params: { activeItem: 'users' },
                }"
              >
                <div class="burgerMenu__adminPanel-link">
                  <div class="burgerMenu__adminPanel-ico">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-people"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z"
                      />
                    </svg>
                  </div>
                  <div class="burgerMenu__adminPanel-text">Пользователи</div>
                </div>
              </router-link>
            </li>
            <li
              class="burgerMenu__adminPanel-item"
              @click.stop="toggleBurgerMenu"
            >
              <router-link
                :to="{
                  name: 'adminPanelPage',
                  params: { activeItem: 'companies' },
                }"
              >
                <div class="burgerMenu__adminPanel-link">
                  <div class="burgerMenu__adminPanel-ico">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-journal-medical"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 4a.5.5 0 0 1 .5.5v.634l.549-.317a.5.5 0 1 1 .5.866L9 6l.549.317a.5.5 0 1 1-.5.866L8.5 6.866V7.5a.5.5 0 0 1-1 0v-.634l-.549.317a.5.5 0 1 1-.5-.866L7 6l-.549-.317a.5.5 0 0 1 .5-.866l.549.317V4.5A.5.5 0 0 1 8 4zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"
                      />
                      <path
                        d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"
                      />
                      <path
                        d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"
                      />
                    </svg>
                  </div>
                  <div class="burgerMenu__adminPanel-text">Все компании</div>
                </div>
              </router-link>
            </li>
            <li
              class="burgerMenu__adminPanel-item"
              @click.stop="toggleBurgerMenu"
            >
              <router-link
                :to="{
                  name: 'adminPanelPage',
                  params: { activeItem: 'billing' },
                }"
              >
                <div class="burgerMenu__adminPanel-link">
                  <div class="burgerMenu__adminPanel-ico">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-cash-stack"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1H1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                      />
                      <path
                        d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2H3z"
                      />
                    </svg>
                  </div>
                  <div class="burgerMenu__adminPanel-text">
                    Система биллинга
                  </div>
                </div>
              </router-link>
            </li>
            <li
              class="burgerMenu__adminPanel-item"
              @click.stop="toggleBurgerMenu"
            >
              <router-link
                :to="{
                  name: 'adminPanelPage',
                  params: { activeItem: 'reports' },
                }"
              >
                <div class="burgerMenu__adminPanel-link">
                  <div class="burgerMenu__adminPanel-ico">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-table"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"
                      />
                    </svg>
                  </div>
                  <div class="burgerMenu__adminPanel-text">Отчёты</div>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="burgerMenu__users">
        <div class="burgerMenu__users-inner" @click.stop="toggleLogoutBtn">
          {{ userData?.login }}
          <ul
            class="burgerMenu__users-list"
            :style="{ display: logoutBtnVisibility ? 'block' : 'none' }"
          >
            <li
              class="burgerMenu__users-item"
              @click.stop="$emit('logoutUser')"
            >
              Выйти
            </li>
          </ul>
        </div>
      </div>
    </div>
    <ul class="tabsForMain">
      <li
        class="tabsForMain__item"
        :style="{
          'border-bottom':
            $router.currentRoute.value.name == 'adminPanelPage'
              ? '2px solid #E31E24'
              : '2px solid transparent',
        }"
        v-if="$store.getters['accessIsAllowed']"
      >
        <router-link to="/admin">
          <div class="tabsForMain__item-tab">Админ панель</div>
        </router-link>
      </li>
      <li
        class="tabsForMain__item"
        :style="{
          'border-bottom':
            $router.currentRoute.value.name == 'schema'
              ? '2px solid #E31E24'
              : '2px solid transparent',
        }"
      >
        <router-link
          :to="$route.params.id ? `/schemes/${$route.params.id}` : '/schemes'"
        >
          <div class="tabsForMain__item-tab">Компании</div>
        </router-link>
      </li>
      <li
        class="tabsForMain__item"
        :style="{
          'border-bottom':
            $router.currentRoute.value.name == 'analytics' ||
            $router.currentRoute.value.name == 'anprReport'
              ? '2px solid #E31E24'
              : '2px solid transparent',
        }"
      >
        <router-link
          :to="
            $route.params.id ? `/analytics/${$route.params.id}` : '/analytics'
          "
        >
          <div class="tabsForMain__item-tab">Аналитика</div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import GrayCover from "@/components/UI/coversForModals/GrayCoverVue.vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";
export default {
  name: "tabsMain",
  emits: ["logoutUser"],
  components: { GrayCover },
  setup() {
    const store = useStore();
    const burgerIsShow = ref(false);
    const adminPanelBurgerVisibility = ref(false);
    const logoutBtnVisibility = ref(false); // logout button visibility
    const userData = computed(() => store.state.mainData.userData);
    const allCompanies = computed(() => store.state.companies.allCompanies);

    const toggleBurgerMenu = () => {
      burgerIsShow.value = !burgerIsShow.value;
      if (logoutBtnVisibility.value) toggleLogoutBtn();
    };
    const toggleAdminPanel = () => {
      adminPanelBurgerVisibility.value = !adminPanelBurgerVisibility.value;
    };
    const toggleLogoutBtn = () => {
      logoutBtnVisibility.value = !logoutBtnVisibility.value;
    };
    const activeCompany = company => {
      // Если это заблокированная компания, то запрещаем CUSTOMER-у click
      if (company.invoice?.isBlocked && !store.getters["accessIsAllowed"])
        return;
      // сохраняем id компании для редактирование групп
      store.commit("setCompanyForGroup", company);
      toggleBurgerMenu();
    };
    return {
      allCompanies,
      userData,
      burgerIsShow,
      adminPanelBurgerVisibility,
      logoutBtnVisibility,
      toggleLogoutBtn,
      toggleBurgerMenu,
      toggleAdminPanel,
      activeCompany,
    };
  },
};
</script>

<style lang="scss" scoped>
@keyframes pulse {
  from {
    transform: scale(0.85);
  }
  50% {
    transform: scale(1);
  }
  to {
    transform: scale(0.85);
  }
}
@keyframes ani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.tabsMain {
  display: flex;
  justify-content: space-between;
  flex: 1;
}
.tabsForMain {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &__item {
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    &-tab {
      padding: 10px 12px;
    }
    &:hover {
      background-color: #f2f2f2;
    }
  }
}
.burger {
  display: none;
  padding: 10px 20px;
}
.burgerMenu {
  position: fixed;
  width: 70%;
  height: 100vh;
  height: 100dvh;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 10;
  padding: 10px 20px;
  transform: translateX(-100%);
  transition: 0.2s all linear;
  border-radius: 0 5px 5px 0;
  li {
    list-style-type: none;
  }
  &__companies {
    padding-bottom: 10px;
    position: relative;
    &-inner {
      width: 100%;
      cursor: pointer;
      transition: all 0.2s ease;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 0.5px;
      background: #b7bacb;
      width: 100%;
    }
  }
  a.router-link-active.router-link-exact-active {
    div {
      background-color: #e31e24;
      color: #fff;
      border-radius: 5px;
      transition: all 0.15s ease-in-out;
    }
    svg {
      fill: #fff;
    }
  }
  &__company {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    &.disabled {
      pointer-events: none;
      color: #b7bacb;
    }
  }
  &__adminPanel {
    position: relative;
    margin-top: 20px;
    &-list {
      opacity: 0;
      animation: ani 2.5s forwards;
    }
    &-link {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 5px;
    }
    &-ico {
      width: 12px;
      height: 12px;
    }
    &-btn {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 5px;
      padding: 10px 0;
      cursor: pointer;
    }
    &-link {
      padding: 8px 16px;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
  &__users {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 20px;
      height: 0.5px;
      background: #b7bacb;
      width: calc(100% - 40px);
    }
    &-list {
      width: fit-content;
      position: absolute;
      top: 0;
      left: 10px;
      transform: translateY(-110%);
      z-index: 3;
      font-weight: 400;
      background-clip: padding-box;
      padding: 8px 0;
      color: inherit;
      background-color: white;
      text-align: left;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 0.25rem;
      &:hover {
        background-color: #f2f2f2;
      }
    }
    &-item {
      padding: 4px 16px;
      transition: all 0.2s ease-in;
      &:hover {
        background-color: #f2f2f2;
      }
    }
  }
  &__users-inner {
    position: relative;
    font-weight: 700;
    width: fit-content;
    cursor: pointer;
    padding: 10px 12px;
    border-radius: 6px;
    &::after {
      content: "";
      display: inline-block;
      margin-left: -0.02em;
      vertical-align: 0.185em;
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
    }
  }
  &__wrap {
    overflow: hidden;
    height: calc(100vh - 170px);
    height: calc(100dvh - 170px);
    overflow-y: auto;
  }
  &__closeBtn {
    width: fit-content;
    margin-bottom: 20px;
  }
}
.burgerMenu__active {
  transform: translateX(0);
  overflow-y: hidden;
}
@media (max-width: 700px) {
  .tabsForMain {
    display: none;
  }
  .burger {
    display: block;
  }
}
@media (max-width: 480px) {
  .burgerMenu {
    &__closeBtn {
      margin: 10px 0 30px 0;
      padding: 10px;
    }
  }
}
</style>
